// Object - page - top

.l-wrap-top-head {
    /*width: calc(1266vw / 13.66);
    margin-left: auto;
    margin-right: auto;*/
    width: 100%;
    & video {
        width: 100%;
        height: auto;
    }
    @include _mq-down(sm) {
        width: 100%;
        //margin-top: _vw(60);
        /*padding-right: 30px;
        padding-left: 30px;*/
    }
}

.top-school-content {
    width: 100%;
    height: _vw(968);
    position: relative;
    &__ttl {
        font-size: _vw(25);
        letter-spacing: 0.15em;
        line-height: 1;
        width: _vw(305);
        position: absolute;
        top: _vw(195);
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1;
        text-align: center;
        padding-bottom: _vw(12);
        border-bottom: 1px solid #000;
    }
    @include _mq-down(sm) {
        height: 100%;
        &__ttl {
            font-size: _vw(16, 375);
            width: _vw(197, 375);
            top: _vw(95, 375);
        }
    }
}

.l-top-support {
    width: 100%;
    height: _vw(918);
    background: url(../../assets/img/top/bg_image_support.jpg) no-repeat;
    background-size: contain;
    padding-top: _vw(280);
    padding-left: _vw(100);
    @include _mq-down(sm) {
        /*height: calc(407vw / 3.75);
        background: url(../../assets/img/top/bg_image_support_sp.jpg) no-repeat;
        background-size: cover;*/
        background: none;
        height: auto;
        padding-top: 0;
        padding-right: _vw(30);
        padding-left: _vw(30);
    }
}

.top-support {
    &__inner {
        position: relative;
        & .item_01 {
            top: 0;
            left: 0;
        }
        & .item_02 {
            top: _vw(40);
            left: _vw(235);
        }
        & .item_03 {
            top: _vw(80);
            left: _vw(470);
        }
        & .item_04 {
            top: _vw(120);
            left: _vw(705);
        }
        & .item_05 {
            top: _vw(160);
            left: _vw(940);
        }
    }
    &__item {
        width: _vw(202);
        position: absolute;
        & .ttl {
            font-size: _vw(22);
            letter-spacing: 0.17em;
            line-height: 1;
            display: flex;
            align-items: center;
            margin-top: _vw(27);
            @include _mq-up(sm) {
                &.adjust {
                    line-height: _vw(27);
                    margin: _vw(17) 0 _vw(-8);
                }
            }
        }
        & .icf-link-arrow {
            font-size: _vw(7);
            padding-right: _vw(4);
            color: #f7931e;
        }
        & .txt {
            font-size: _vw(13);
            line-height: 1.9231;
            margin-top: _vw(19);
        }
    }
}
@include _mq-down(sm) {
    .top-support {
        &__inner {
            position: static;
            & .item_02, .item_03, .item_04, .item_05{
                margin-top: _vw(30, 375);
            }
        }
        &__item {
            position: static;
            width: 100%;
            & .ttl {
                font-size: _vw(20, 375);
                margin-top: _vw(17, 375);
                display: flex;
                justify-content: center;
            }
            & .icf-link-arrow {
                font-size: _vw(7, 375);
                padding-right: _vw(4, 375);
            }
            & .txt {
                font-size: _vw(13, 375);
                line-height: 1.7692;
                text-align: center;
                margin-top: _vw(10, 375);
            }
        }
    }
}

/*i.icf-link-arrow {
    display: flex;
}*/

.top-support-content {
    &__img {
        width: _vw(910);
        @include _mq-down(sm) {
            width: 100%;
        }
    }
    &__box {
        width: _vw(695);
        height: _vw(769);
        position: absolute;
        top: _vw(200);
        right: 0;
        background: #fff;
        padding-top: _vw(96);
        padding-left: _vw(96);
        & .number {
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        & .number-cover {
            position: absolute;
            height: 0;
            overflow: hidden;
            transition: 2s;
            &.num-01 {
                right: _vw(143);
                top: _vw(-84);
                width: _vw(134);
                &.is-Active {
                    height: _vw(348);
                }
                @include _mq-down(sm) {
                    right: _vw(68, 375);
                    top: _vw(-7, 375);
                    width: _vw(76, 375);
                    &.is-Active {
                        height: _vw(197, 375);
                    }
                }
            }
            &.num-02 {
                left: _vw(208);
                top: _vw(-84);
                width: _vw(225);
                &.is-Active {
                    height: _vw(348);
                }
                @include _mq-down(sm) {
                    right: _vw(44, 375);
                    left: auto;
                    top: _vw(-6, 375);
                    width: _vw(128, 375);
                    &.is-Active {
                        height: _vw(197, 375);
                    }
                }
            }
            &.num-03 {
                right: _vw(126);
                top: _vw(-84);
                width: _vw(194);
                &.is-Active {
                    height: _vw(354);
                }
                @include _mq-down(sm) {
                    right: _vw(55, 375);
                    left: auto;
                    top: _vw(-7, 375);
                    width: _vw(110, 375);
                    &.is-Active {
                        height: _vw(201, 375);
                    }
                }
            }
            &.num-04 {
                left: _vw(320);
                top: _vw(-90);
                width: _vw(231);
                &.is-Active {
                    height: _vw(384);
                }
                @include _mq-down(sm) {
                    left: auto;
                    right: _vw(42, 375);
                    top: _vw(-7, 375);
                    width: _vw(131, 375);
                    &.is-Active {
                        height: _vw(197, 375);
                    }
                }
            }
            &.num-05 {
                right: _vw(117);
                top: _vw(-77);
                width: _vw(198);
                &.is-Active {
                    height: _vw(346);
                }
                @include _mq-down(sm) {
                    right: _vw(51, 375);
                    left: auto;
                    top: _vw(-4, 375);
                    width: _vw(113, 375);
                    &.is-Active {
                        height: _vw(197, 375);
                    }
                }
            }
        }
        & h3 {
            line-height: 1;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: _vw(-146);
                left: _vw(50);
                width: _vw(5);
                height: _vw(100);
                background: #f7931e;
            }
        }
        & .en-upper {
            font-size: _vw(35);
            letter-spacing: 0.12em;
        }
        & .en-main {
            font-size: _vw(60.4);
            margin-top: _vw(10);
            letter-spacing: 0.12em;
        }
        & .jp {
            font-size: _vw(22);
            margin-top: _vw(15);
            letter-spacing: 0.2em;
        }
        @include _mq-down(sm) {
            //position: static;
            width: calc(100% - 30px);
            left: _vw(30, 375);
            right: auto;
            height: auto;
            padding-top: _vw(70, 375);
            padding-right: _vw(30, 375);
            padding-left: _vw(30, 375);
            margin-top: _vw(162, 375);
            & h3::before {
                top: _vw(-100, 375);
                width: _vw(2, 375);
                height: _vw(60, 375);
            }
            & .en-upper {
                font-size: _vw(20, 375);
            }
            & .en-main {
                font-size: _vw(35, 375);
                margin-top: _vw(5, 375);
            }
            & .jp {
                font-size: _vw(11.5, 375);
                margin-top: _vw(8, 375);
            }
        }
    }

    &__lead {
        font-size: _vw(27.5);
        line-height: 1.92;
        margin-top: _vw(56);
        letter-spacing: 0.15em;
    }
    &__txt {
        font-size: _vw(15);
        line-height: 2.3333;
        width: _vw(427);
        text-align: justify;
        margin-top: _vw(10);
    }
    &__linkbox {
        & a {
            width: 100%;
            height: _vw(50);
            border: 1px solid $color-brand;
            font-size: _vw(19);
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.2em;
            margin-top: _vw(30);
            width: _vw(427);
        }
        & i {
            color: $color-brand;
            font-size: _vw(8);
            margin-right: _vw(5);
        }
    }
    @include _mq-down(sm) {
        &__lead {
            font-size: _vw(20, 375);
            margin-top: _vw(65, 375);
        }
        &__txt {
            font-size: _vw(13, 375);
            width: 100%;
            margin-top: _vw(10, 375);
            line-height: 2;
        }
        &__linkbox {
            a {
                height: _vw(45, 375);
                font-size: _vw(17, 375);
                margin-top: _vw(30, 375);
                width: 100%;
                letter-spacing: 0.15em;
            }
            & i {
                color: $color-brand;
                font-size: _vw(8, 375);
                margin-right: _vw(7, 375);
            }
        }
    }
}

//SVG
.top-support-content__box {
    & .svgall .mask {
        width: _vw(350);
        height: _vw(400);
    }
    & .num-02-svg {
        position: absolute;
        top: _vw(-110);
        left: _vw(155);
    }
    & .num-03-svg {
        position: absolute;
        top: _vw(-110);
        left: _vw(300);
    }
    @include _mq-down(sm) {
        & .svgall .mask {
            width: _vw(200, 375);
            height: _vw(228, 375);
        }
        & .num-02-svg {
            top: _vw(-30, 375);
            right: _vw(7, 375);
            left: auto;
        }
        & .num-03-svg {
            top: _vw(-30, 375);
            right: _vw(7, 375);
            left: auto;
        }
    }
}

.l-top-collabo {
    position: relative;
    height: _vw(895);
    @include _mq-down(sm) {
        height: _vw(810, 375);
        margin-top: _vw(118, 375);
    }
}

.top-collabo {

}

.l-top-pro {
    width: 100%;
    position: relative;
    background: url(../../assets/img/top/bg_image_pro_01.jpg) no-repeat;
    height: _vw(968);
    background-size: _vw(941) _vw(694);
    background-position: bottom left;
    @include _mq-down(sm) {
        height: _vw(905, 375);
    }
}

.second .top-support-content__img {
    width: _vw(1028);
    height: _vw(672);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    @include _mq-down(sm) {
        width: 100%;
        height: auto;
    }
}

.second .top-support-content__box {
    height: _vw(330);
    left: _vw(100);
    background: transparent;
    @include _mq-down(sm) {
        padding-right: 0;
        & .en-main {
            letter-spacing: 0.05em;
        }
    }
}

.second .top-support-content__lead {
    margin-top: _vw(152);
    margin-left: _vw(428);
    width: _vw(570);
    @include _mq-down(sm) {
        margin-left: 0;
        width: 100%;
        padding-right: _vw(30, 375);
        margin-top: _vw(65, 375);
    }
}

.second .top-support-content__txt {
    margin-left: _vw(428);
    width: _vw(570);
    @include _mq-down(sm) {
        margin-left: 0;
        width: 100%;
        padding-right: _vw(30, 375);
    }
}

.second .top-support-content__linkbox {
    margin-left: _vw(572);
}
@include _mq-down(sm) {
    .second .top-support-content__linkbox {
        margin-left: 0;
        padding-right: _vw(30, 375);
    }
}

.second .top-support-content__inner .en-upper {
    letter-spacing: 0.08em;
}
.second .top-support-content__inner .en-main {
    letter-spacing: 0.08em;
}

@include _mq-down(sm) {
    .third .top-support-content__img {
        padding-right: _vw(4, 375);
    }
}

.l-top-experi {
    position: relative;
    height: _vw(905);
    margin-top: _vw(9);
    @include _mq-down(sm) {
        height: _vw(805, 375);
        margin-top: _vw(3, 375);
    }
    &.fifth {
        margin-top: 0;
        height: _vw(868);
        @include _mq-down(sm) {
            height: _vw(770, 375);
            margin-top: _vw(100);
        }
    }
}

.l-top-company {
    width: _vw(1000);
    height: _vw(668);
    background: #f2f2f2;
    margin: _vw(155) auto 0;
    &__sec {
        position: relative;
    }
    @include _mq-down(sm) {
        width: calc(100% - 60px);
        height: _vw(500, 375);
        margin-right: auto;
        margin-left: auto;
    }
}

.top-company-content {
    position: relative;
    height: 100%;
    &::before {
        content: '';
        background: #f7931e;
        width: 1px;
        height: _vw(100);
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: _vw(-50);
    }
    &::after {
        content: '';
        background: #f7931e;
        width: 1px;
        height: _vw(100);
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: _vw(-50);
    }
    &__box {
        padding-top: _vw(132);
        position: relative;
        &::after {

        }
        & .ttl {
            text-align: center;
            line-height: 1;
            & .sub {
                font-size: _vw(24.3);
                letter-spacing: 0.1em;
            }
            & .main {
                font-size: _vw(49);
                margin-top: _vw(4);
                letter-spacing: 0.1em;
            }
        }
    }
    @include _mq-down(sm) {
        &::before {
            height: _vw(100, 375);
            top: _vw(-50, 375);
        }
        &::after {
            height: _vw(100, 375);
            bottom: _vw(-50, 375);
        }
        &__box {
            padding-top: _vw(123, 375);
            & .ttl {
                & .sub {
                    font-size: _vw(24.5, 375);
                }
                & .main {
                    font-size: _vw(42, 375);
                }
            }
        }
    }
}

.l-top-found {
    width: 100%;
    position: relative;
    height: _vw(890);
    @include _mq-down(sm) {
        margin-top: 0;
        height: auto;
    }
}

.fourth .top-support-content {
    &__img {
        width: 100%;
        height: _vw(422);
        background: url(../../assets/img/top/bg_image_found.jpg) no-repeat;
        background-size: 100% 100%;
    }
    &__box {
        background: none;
        position: relative;
        top: 0;
        left: 0;
        right: auto;
        padding-left: _vw(198);
        margin-right: _vw(170);
        height: auto;
        width: auto;
    }
    &__txtarea {
        position: absolute;
        top: 0;
        right: 0;
    }
    @include _mq-down(sm) {
        height: auto;
        &__box {
            padding-left: _vw(55, 375);
            margin-top: 0;
            margin-right: 0;
        }
        &__txtarea {
            position: static;
            width: 100%;
        }
    }
}


/**
* top-school-slider
**/
.top-school-slider{
  display: none;
}
.top-school-slider.slick-initialized{
  display: block; /*slick-initializedが付与されたら表示*/
}
.top-school-slider {
    & .slick-slide img {
        width: 100%;
    }
    & li {
        position: relative;
    }
    & a {
        display: block;
    }
    &__name {
        position: absolute;
        left: 0;
        right: 0;
        bottom: _vw(490);
        margin: 0 auto;
        text-align: center;
        @include _mq-down(sm) {
            bottom: _vw(255, 375);
        }
        & .en {
            font-size: _vw(60);
            line-height: 1.1667;
            @include _mq-down(sm) {
                font-size: _vw(31, 375);
                line-height: 1.1935;
            }
        }
        & .jp {
            font-size: _vw(16);
            line-height: 1;
            margin-top: _vw(16);
            @include _mq-down(sm) {
                font-size: _vw(10, 375);
                margin-top: _vw(11, 375);
            }
        }
        & .pet { color: #00b9e7;}
        & .animal { color: #ed82b1}
        & .beauty { color: #be1e28;}
        & .medical { color: #f8b500;}
        & .hotel-bridal { color: #0074be;}
        & .sweets { color: #953b2e;}
        & .cooking { color: #928977;}
        & .management { color: #000080; letter-spacing: -0.04em;}
        & .management { transform: scale(0.9, 1);}
    }
}

/**
* top-loop-slider
**/

.top-loop-slider {
    &__wrap {
        position: absolute;
        bottom: _vw(193);
    }
    & li {
        width: _vw(250);
    }
    & li div {
        margin-right: _vw(25);
        margin-left: _vw(25);
    }
    & .slick-slide img {
        display: block;
        background: #ccc;
        width: 100%;
        height: 100%;
    }
    @include _mq-down(sm) {
        &__wrap {
            bottom: _vw(157, 375);
        }
        & li {
            width: _vw(150, 375);
        }
        & li div {
            margin-right: _vw(15, 375);
            margin-left: _vw(15, 375);
        }
    }
}

/**
* スクロールイベント
**/

.l-top-support {
    & .item_01, .item_02, .item_03, .item_04, .item_05{
        transform: translateY(200px);
        transition: 1s;
        opacity: 0;
    }
}
.l-top-support .is-Active {
    & .item_01 {
        transform: translateY(0px);
        transition: 1s 0;
        opacity: 1;
    }
    & .item_02 {
        transform: translateY(0px);
        transition: 1s 0.5s;
        opacity: 1;
    }
    & .item_03 {
        transform: translateY(0px);
        transition: 1s 1s;
        opacity: 1;
    }
    & .item_04 {
        transform: translateY(0px);
        transition: 1s 1.5s;
        opacity: 1;
    }
    & .item_05 {
        transform: translateY(0px);
        transition: 1s 2s;
        opacity: 1;
    }
}

.top-support-content__left {
    display: block;
    transform: translateX(-600px);
    transition: 2s;
    opacity: 0;
}
.top-support-content__left.is-Active {
    transform: translateX(0);
    opacity: 1;
}
.top-support-content__fade {
    display: block;
    transition: 3s;
    opacity: 0;
}
.top-support-content__fade.is-Active {
    opacity: 1;
}


/*
.top-heading-ttl {
    text-align: center;
    & .en {
        font-size: 1.4rem;
        line-height: 1;
    }
    & .jp {
        font-size: 3.0rem;
        line-height: 1;
        margin-top: 42px;
        @include _mq-down(md) {
            font-size: 2.2rem;
            margin-top: 27px;
        }
    }
}

h3.top-comment-ttl {
    font-size: 2.0rem;
    @include _mq-down(md) {
        font-size: 1.6rem;
    }
}
h4.top-comment-ttl {
    font-size: 1.8rem;
    @include _mq-down(md) {
        font-size: 1.5rem;
    }
}
.top-comment-ttl {
    line-height: 2;
    text-align: center;
    & span {
        position: relative;
        padding-left: 2em;
    }
    & span::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 0.5em;
        width: 0.7em;
        height: 1px;
        background-color: #000;
    }
    @include _mq-down(sm) {
        font-size: 1.5rem;
        & span.border_pc-only {
            padding-left: 0;
            display: inline-block;
        }
        & span.border_pc-only::before {
            display: none;
        }
    }
}
*/
/* ------------------------------
   loopSlider
------------------------------
.loopSliderWrap {
    top: 0;
    left: 0;
    height: 200px;
    overflow: hidden;
    position: absolute;
}

.loopSlider {
    margin: 0 auto;
    width: 100%;
    height: 200px;
    text-align: left;
    position: relative;
    overflow: hidden;
}

.loopSlider ul {
    height: 200px;
    float: left;
    overflow: hidden;
}

.loopSlider ul li {
    width: calc(310px + 25px);
    height: 200px;
    float: left;
    display: inline;
    overflow: hidden;
    padding-left: 25px;
}
*/
/* ------------------------------
   CLEARFIX ELEMENTS
------------------------------
.loopSliderWrap:after {
    content: "";
    display: none;
    clear: none;
}
*/
