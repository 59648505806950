@charset "UTF-8";
/*normal*/
/*@function vw($px, $width: 375) {
    @return (strip-unit($px) / strip-unit($width)) * 100vw;
}*/
.l-top-news {
  width: 100%;
  position: relative;
  margin: 4.02635vw auto 4.02635vw;
  width: 85.35871vw;
  display: flex; }
  @media screen and (max-width: 499px) {
    .l-top-news {
      width: 100%;
      margin: 10.66667vw auto 13.33333vw;
      padding: 0 8vw;
      display: block; } }

.post-content-ttl {
  font-size: 1.83016vw;
  width: 17.20351vw;
  line-height: 1;
  letter-spacing: 0.1em; }
  @media screen and (max-width: 499px) {
    .post-content-ttl {
      font-size: 5.33333vw;
      width: auto; } }

.post-list {
  line-height: 1; }

.post-list + .post-list {
  margin-top: 1.83016vw; }
  @media screen and (max-width: 499px) {
    .post-list + .post-list {
      margin-top: 6.66667vw; } }

.post-date {
  font-size: 1.1713vw;
  width: 7.68668vw;
  letter-spacing: 0.1em;
  font-weight: 400; }
  @media screen and (max-width: 499px) {
    .post-date {
      width: auto;
      font-size: 3.2vw; } }

.post-ttl {
  font-size: 1.0981vw;
  letter-spacing: 0.05em;
  width: 60.54173vw; }
  @media screen and (max-width: 499px) {
    .post-ttl {
      font-size: 3.46667vw;
      line-height: 1.7692;
      padding-top: 1.33333vw;
      width: 100%; } }

.post-wrap {
  width: 68.2284vw; }

@media screen and (max-width: 499px) {
  .post-wrap {
    margin-top: 9.33333vw;
    width: 100%; } }

.post-more {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 1.0981vw;
  line-height: 1;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em; }
  .post-more .icf-link-arrow::before {
    font-size: 0.51245vw;
    padding-right: 0.29283vw;
    color: #f7931e; }
  .post-more a {
    display: block; }
  @media screen and (max-width: 499px) {
    .post-more {
      position: static;
      margin-top: 9.33333vw;
      font-size: 4vw; }
      .post-more .icf-link-arrow::before {
        font-size: 1.86667vw;
        padding-right: 1.06667vw; } }

.l-wrap-top-head {
  /*width: calc(1266vw / 13.66);
    margin-left: auto;
    margin-right: auto;*/
  width: 100%; }
  .l-wrap-top-head video {
    width: 100%;
    height: auto; }
  @media screen and (max-width: 499px) {
    .l-wrap-top-head {
      width: 100%;
      /*padding-right: 30px;
        padding-left: 30px;*/ } }

.top-school-content {
  width: 100%;
  height: 70.86384vw;
  position: relative; }
  .top-school-content__ttl {
    font-size: 1.83016vw;
    letter-spacing: 0.15em;
    line-height: 1;
    width: 22.32796vw;
    position: absolute;
    top: 14.27526vw;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    text-align: center;
    padding-bottom: 0.87848vw;
    border-bottom: 1px solid #000; }
  @media screen and (max-width: 499px) {
    .top-school-content {
      height: 100%; }
      .top-school-content__ttl {
        font-size: 4.26667vw;
        width: 52.53333vw;
        top: 25.33333vw; } }

.l-top-support {
  width: 100%;
  height: 67.20351vw;
  background: url(../../assets/img/top/bg_image_support.jpg) no-repeat;
  background-size: contain;
  padding-top: 20.4978vw;
  padding-left: 7.32064vw; }
  @media screen and (max-width: 499px) {
    .l-top-support {
      /*height: calc(407vw / 3.75);
        background: url(../../assets/img/top/bg_image_support_sp.jpg) no-repeat;
        background-size: cover;*/
      background: none;
      height: auto;
      padding-top: 0;
      padding-right: 2.19619vw;
      padding-left: 2.19619vw; } }

.top-support__inner {
  position: relative; }
  .top-support__inner .item_01 {
    top: 0;
    left: 0; }
  .top-support__inner .item_02 {
    top: 2.92826vw;
    left: 17.20351vw; }
  .top-support__inner .item_03 {
    top: 5.85652vw;
    left: 34.40703vw; }
  .top-support__inner .item_04 {
    top: 8.78477vw;
    left: 51.61054vw; }
  .top-support__inner .item_05 {
    top: 11.71303vw;
    left: 68.81406vw; }

.top-support__item {
  width: 14.7877vw;
  position: absolute; }
  .top-support__item .ttl {
    font-size: 1.61054vw;
    letter-spacing: 0.17em;
    line-height: 1;
    display: flex;
    align-items: center;
    margin-top: 1.97657vw; }
    @media screen and (min-width: 500px) {
      .top-support__item .ttl.adjust {
        line-height: 1.97657vw;
        margin: 1.24451vw 0 -0.58565vw; } }
  .top-support__item .icf-link-arrow {
    font-size: 0.51245vw;
    padding-right: 0.29283vw;
    color: #f7931e; }
  .top-support__item .txt {
    font-size: 0.95168vw;
    line-height: 1.9231;
    margin-top: 1.39092vw; }

@media screen and (max-width: 499px) {
  .top-support__inner {
    position: static; }
    .top-support__inner .item_02, .top-support__inner .item_03, .top-support__inner .item_04, .top-support__inner .item_05 {
      margin-top: 8vw; }
  .top-support__item {
    position: static;
    width: 100%; }
    .top-support__item .ttl {
      font-size: 5.33333vw;
      margin-top: 4.53333vw;
      display: flex;
      justify-content: center; }
    .top-support__item .icf-link-arrow {
      font-size: 1.86667vw;
      padding-right: 1.06667vw; }
    .top-support__item .txt {
      font-size: 3.46667vw;
      line-height: 1.7692;
      text-align: center;
      margin-top: 2.66667vw; } }

/*i.icf-link-arrow {
    display: flex;
}*/
.top-support-content__img {
  width: 66.61786vw; }
  @media screen and (max-width: 499px) {
    .top-support-content__img {
      width: 100%; } }

.top-support-content__box {
  width: 50.87848vw;
  height: 56.29575vw;
  position: absolute;
  top: 14.64129vw;
  right: 0;
  background: #fff;
  padding-top: 7.02782vw;
  padding-left: 7.02782vw; }
  .top-support-content__box .number {
    right: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .top-support-content__box .number-cover {
    position: absolute;
    height: 0;
    overflow: hidden;
    transition: 2s; }
    .top-support-content__box .number-cover.num-01 {
      right: 10.46852vw;
      top: -6.14934vw;
      width: 9.80966vw; }
      .top-support-content__box .number-cover.num-01.is-Active {
        height: 25.47584vw; }
      @media screen and (max-width: 499px) {
        .top-support-content__box .number-cover.num-01 {
          right: 18.13333vw;
          top: -1.86667vw;
          width: 20.26667vw; }
          .top-support-content__box .number-cover.num-01.is-Active {
            height: 52.53333vw; } }
    .top-support-content__box .number-cover.num-02 {
      left: 15.22694vw;
      top: -6.14934vw;
      width: 16.47145vw; }
      .top-support-content__box .number-cover.num-02.is-Active {
        height: 25.47584vw; }
      @media screen and (max-width: 499px) {
        .top-support-content__box .number-cover.num-02 {
          right: 11.73333vw;
          left: auto;
          top: -1.6vw;
          width: 34.13333vw; }
          .top-support-content__box .number-cover.num-02.is-Active {
            height: 52.53333vw; } }
    .top-support-content__box .number-cover.num-03 {
      right: 9.22401vw;
      top: -6.14934vw;
      width: 14.20205vw; }
      .top-support-content__box .number-cover.num-03.is-Active {
        height: 25.91508vw; }
      @media screen and (max-width: 499px) {
        .top-support-content__box .number-cover.num-03 {
          right: 14.66667vw;
          left: auto;
          top: -1.86667vw;
          width: 29.33333vw; }
          .top-support-content__box .number-cover.num-03.is-Active {
            height: 53.6vw; } }
    .top-support-content__box .number-cover.num-04 {
      left: 23.42606vw;
      top: -6.58858vw;
      width: 16.91069vw; }
      .top-support-content__box .number-cover.num-04.is-Active {
        height: 28.11127vw; }
      @media screen and (max-width: 499px) {
        .top-support-content__box .number-cover.num-04 {
          left: auto;
          right: 11.2vw;
          top: -1.86667vw;
          width: 34.93333vw; }
          .top-support-content__box .number-cover.num-04.is-Active {
            height: 52.53333vw; } }
    .top-support-content__box .number-cover.num-05 {
      right: 8.56515vw;
      top: -5.6369vw;
      width: 14.49488vw; }
      .top-support-content__box .number-cover.num-05.is-Active {
        height: 25.32943vw; }
      @media screen and (max-width: 499px) {
        .top-support-content__box .number-cover.num-05 {
          right: 13.6vw;
          left: auto;
          top: -1.06667vw;
          width: 30.13333vw; }
          .top-support-content__box .number-cover.num-05.is-Active {
            height: 52.53333vw; } }
  .top-support-content__box h3 {
    line-height: 1;
    position: relative; }
    .top-support-content__box h3::before {
      content: '';
      position: absolute;
      top: -10.68814vw;
      left: 3.66032vw;
      width: 0.36603vw;
      height: 7.32064vw;
      background: #f7931e; }
  .top-support-content__box .en-upper {
    font-size: 2.56223vw;
    letter-spacing: 0.12em; }
  .top-support-content__box .en-main {
    font-size: 4.42167vw;
    margin-top: 0.73206vw;
    letter-spacing: 0.12em; }
  .top-support-content__box .jp {
    font-size: 1.61054vw;
    margin-top: 1.0981vw;
    letter-spacing: 0.2em; }
  @media screen and (max-width: 499px) {
    .top-support-content__box {
      width: calc(100% - 30px);
      left: 8vw;
      right: auto;
      height: auto;
      padding-top: 18.66667vw;
      padding-right: 8vw;
      padding-left: 8vw;
      margin-top: 43.2vw; }
      .top-support-content__box h3::before {
        top: -26.66667vw;
        width: 0.53333vw;
        height: 16vw; }
      .top-support-content__box .en-upper {
        font-size: 5.33333vw; }
      .top-support-content__box .en-main {
        font-size: 9.33333vw;
        margin-top: 1.33333vw; }
      .top-support-content__box .jp {
        font-size: 3.06667vw;
        margin-top: 2.13333vw; } }

.top-support-content__lead {
  font-size: 2.01318vw;
  line-height: 1.92;
  margin-top: 4.09956vw;
  letter-spacing: 0.15em; }

.top-support-content__txt {
  font-size: 1.0981vw;
  line-height: 2.3333;
  width: 31.25915vw;
  text-align: justify;
  margin-top: 0.73206vw; }

.top-support-content__linkbox a {
  width: 100%;
  height: 3.66032vw;
  border: 1px solid #f7931e;
  font-size: 1.39092vw;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.2em;
  margin-top: 2.19619vw;
  width: 31.25915vw; }

.top-support-content__linkbox i {
  color: #f7931e;
  font-size: 0.58565vw;
  margin-right: 0.36603vw; }

@media screen and (max-width: 499px) {
  .top-support-content__lead {
    font-size: 5.33333vw;
    margin-top: 17.33333vw; }
  .top-support-content__txt {
    font-size: 3.46667vw;
    width: 100%;
    margin-top: 2.66667vw;
    line-height: 2; }
  .top-support-content__linkbox a {
    height: 12vw;
    font-size: 4.53333vw;
    margin-top: 8vw;
    width: 100%;
    letter-spacing: 0.15em; }
  .top-support-content__linkbox i {
    color: #f7931e;
    font-size: 2.13333vw;
    margin-right: 1.86667vw; } }

.top-support-content__box .svgall .mask {
  width: 25.62225vw;
  height: 29.28258vw; }

.top-support-content__box .num-02-svg {
  position: absolute;
  top: -8.05271vw;
  left: 11.347vw; }

.top-support-content__box .num-03-svg {
  position: absolute;
  top: -8.05271vw;
  left: 21.96193vw; }

@media screen and (max-width: 499px) {
  .top-support-content__box .svgall .mask {
    width: 53.33333vw;
    height: 60.8vw; }
  .top-support-content__box .num-02-svg {
    top: -8vw;
    right: 1.86667vw;
    left: auto; }
  .top-support-content__box .num-03-svg {
    top: -8vw;
    right: 1.86667vw;
    left: auto; } }

.l-top-collabo {
  position: relative;
  height: 65.51977vw; }
  @media screen and (max-width: 499px) {
    .l-top-collabo {
      height: 216vw;
      margin-top: 31.46667vw; } }

.l-top-pro {
  width: 100%;
  position: relative;
  background: url(../../assets/img/top/bg_image_pro_01.jpg) no-repeat;
  height: 70.86384vw;
  background-size: 68.88726vw 50.80527vw;
  background-position: bottom left; }
  @media screen and (max-width: 499px) {
    .l-top-pro {
      height: 241.33333vw; } }

.second .top-support-content__img {
  width: 75.25622vw;
  height: 49.19473vw;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0; }
  @media screen and (max-width: 499px) {
    .second .top-support-content__img {
      width: 100%;
      height: auto; } }

.second .top-support-content__box {
  height: 24.15813vw;
  left: 7.32064vw;
  background: transparent; }
  @media screen and (max-width: 499px) {
    .second .top-support-content__box {
      padding-right: 0; }
      .second .top-support-content__box .en-main {
        letter-spacing: 0.05em; } }

.second .top-support-content__lead {
  margin-top: 11.12738vw;
  margin-left: 31.33236vw;
  width: 41.72767vw; }
  @media screen and (max-width: 499px) {
    .second .top-support-content__lead {
      margin-left: 0;
      width: 100%;
      padding-right: 8vw;
      margin-top: 17.33333vw; } }

.second .top-support-content__txt {
  margin-left: 31.33236vw;
  width: 41.72767vw; }
  @media screen and (max-width: 499px) {
    .second .top-support-content__txt {
      margin-left: 0;
      width: 100%;
      padding-right: 8vw; } }

.second .top-support-content__linkbox {
  margin-left: 41.87408vw; }

@media screen and (max-width: 499px) {
  .second .top-support-content__linkbox {
    margin-left: 0;
    padding-right: 8vw; } }

.second .top-support-content__inner .en-upper {
  letter-spacing: 0.08em; }

.second .top-support-content__inner .en-main {
  letter-spacing: 0.08em; }

@media screen and (max-width: 499px) {
  .third .top-support-content__img {
    padding-right: 1.06667vw; } }

.l-top-experi {
  position: relative;
  height: 66.25183vw;
  margin-top: 0.65886vw; }
  @media screen and (max-width: 499px) {
    .l-top-experi {
      height: 214.66667vw;
      margin-top: 0.8vw; } }
  .l-top-experi.fifth {
    margin-top: 0;
    height: 63.54319vw; }
    @media screen and (max-width: 499px) {
      .l-top-experi.fifth {
        height: 205.33333vw;
        margin-top: 7.32064vw; } }

.l-top-company {
  width: 73.20644vw;
  height: 48.9019vw;
  background: #f2f2f2;
  margin: 11.347vw auto 0; }
  .l-top-company__sec {
    position: relative; }
  @media screen and (max-width: 499px) {
    .l-top-company {
      width: calc(100% - 60px);
      height: 133.33333vw;
      margin-right: auto;
      margin-left: auto; } }

.top-company-content {
  position: relative;
  height: 100%; }
  .top-company-content::before {
    content: '';
    background: #f7931e;
    width: 1px;
    height: 7.32064vw;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -3.66032vw; }
  .top-company-content::after {
    content: '';
    background: #f7931e;
    width: 1px;
    height: 7.32064vw;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -3.66032vw; }
  .top-company-content__box {
    padding-top: 9.66325vw;
    position: relative; }
    .top-company-content__box .ttl {
      text-align: center;
      line-height: 1; }
      .top-company-content__box .ttl .sub {
        font-size: 1.77892vw;
        letter-spacing: 0.1em; }
      .top-company-content__box .ttl .main {
        font-size: 3.58712vw;
        margin-top: 0.29283vw;
        letter-spacing: 0.1em; }
  @media screen and (max-width: 499px) {
    .top-company-content::before {
      height: 26.66667vw;
      top: -13.33333vw; }
    .top-company-content::after {
      height: 26.66667vw;
      bottom: -13.33333vw; }
    .top-company-content__box {
      padding-top: 32.8vw; }
      .top-company-content__box .ttl .sub {
        font-size: 6.53333vw; }
      .top-company-content__box .ttl .main {
        font-size: 11.2vw; } }

.l-top-found {
  width: 100%;
  position: relative;
  height: 65.15373vw; }
  @media screen and (max-width: 499px) {
    .l-top-found {
      margin-top: 0;
      height: auto; } }

.fourth .top-support-content__img {
  width: 100%;
  height: 30.89312vw;
  background: url(../../assets/img/top/bg_image_found.jpg) no-repeat;
  background-size: 100% 100%; }

.fourth .top-support-content__box {
  background: none;
  position: relative;
  top: 0;
  left: 0;
  right: auto;
  padding-left: 14.49488vw;
  margin-right: 12.4451vw;
  height: auto;
  width: auto; }

.fourth .top-support-content__txtarea {
  position: absolute;
  top: 0;
  right: 0; }

@media screen and (max-width: 499px) {
  .fourth .top-support-content {
    height: auto; }
    .fourth .top-support-content__box {
      padding-left: 14.66667vw;
      margin-top: 0;
      margin-right: 0; }
    .fourth .top-support-content__txtarea {
      position: static;
      width: 100%; } }

/**
* top-school-slider
**/
.top-school-slider {
  display: none; }

.top-school-slider.slick-initialized {
  display: block;
  /*slick-initializedが付与されたら表示*/ }

.top-school-slider .slick-slide img {
  width: 100%; }

.top-school-slider li {
  position: relative; }

.top-school-slider a {
  display: block; }

.top-school-slider__name {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 35.87116vw;
  margin: 0 auto;
  text-align: center; }
  @media screen and (max-width: 499px) {
    .top-school-slider__name {
      bottom: 68vw; } }
  .top-school-slider__name .en {
    font-size: 4.39239vw;
    line-height: 1.1667; }
    @media screen and (max-width: 499px) {
      .top-school-slider__name .en {
        font-size: 8.26667vw;
        line-height: 1.1935; } }
  .top-school-slider__name .jp {
    font-size: 1.1713vw;
    line-height: 1;
    margin-top: 1.1713vw; }
    @media screen and (max-width: 499px) {
      .top-school-slider__name .jp {
        font-size: 2.66667vw;
        margin-top: 2.93333vw; } }
  .top-school-slider__name .pet {
    color: #00b9e7; }
  .top-school-slider__name .animal {
    color: #ed82b1; }
  .top-school-slider__name .beauty {
    color: #be1e28; }
  .top-school-slider__name .medical {
    color: #f8b500; }
  .top-school-slider__name .hotel-bridal {
    color: #0074be; }
  .top-school-slider__name .sweets {
    color: #953b2e; }
  .top-school-slider__name .cooking {
    color: #928977; }
  .top-school-slider__name .management {
    color: #000080;
    letter-spacing: -0.04em; }
  .top-school-slider__name .management {
    transform: scale(0.9, 1); }

/**
* top-loop-slider
**/
.top-loop-slider__wrap {
  position: absolute;
  bottom: 14.12884vw; }

.top-loop-slider li {
  width: 18.30161vw; }

.top-loop-slider li div {
  margin-right: 1.83016vw;
  margin-left: 1.83016vw; }

.top-loop-slider .slick-slide img {
  display: block;
  background: #ccc;
  width: 100%;
  height: 100%; }

@media screen and (max-width: 499px) {
  .top-loop-slider__wrap {
    bottom: 41.86667vw; }
  .top-loop-slider li {
    width: 40vw; }
  .top-loop-slider li div {
    margin-right: 4vw;
    margin-left: 4vw; } }

/**
* スクロールイベント
**/
.l-top-support .item_01, .l-top-support .item_02, .l-top-support .item_03, .l-top-support .item_04, .l-top-support .item_05 {
  transform: translateY(200px);
  transition: 1s;
  opacity: 0; }

.l-top-support .is-Active .item_01 {
  transform: translateY(0px);
  transition: 1s 0;
  opacity: 1; }

.l-top-support .is-Active .item_02 {
  transform: translateY(0px);
  transition: 1s 0.5s;
  opacity: 1; }

.l-top-support .is-Active .item_03 {
  transform: translateY(0px);
  transition: 1s 1s;
  opacity: 1; }

.l-top-support .is-Active .item_04 {
  transform: translateY(0px);
  transition: 1s 1.5s;
  opacity: 1; }

.l-top-support .is-Active .item_05 {
  transform: translateY(0px);
  transition: 1s 2s;
  opacity: 1; }

.top-support-content__left {
  display: block;
  transform: translateX(-600px);
  transition: 2s;
  opacity: 0; }

.top-support-content__left.is-Active {
  transform: translateX(0);
  opacity: 1; }

.top-support-content__fade {
  display: block;
  transition: 3s;
  opacity: 0; }

.top-support-content__fade.is-Active {
  opacity: 1; }

/*
.top-heading-ttl {
    text-align: center;
    & .en {
        font-size: 1.4rem;
        line-height: 1;
    }
    & .jp {
        font-size: 3.0rem;
        line-height: 1;
        margin-top: 42px;
        @include _mq-down(md) {
            font-size: 2.2rem;
            margin-top: 27px;
        }
    }
}

h3.top-comment-ttl {
    font-size: 2.0rem;
    @include _mq-down(md) {
        font-size: 1.6rem;
    }
}
h4.top-comment-ttl {
    font-size: 1.8rem;
    @include _mq-down(md) {
        font-size: 1.5rem;
    }
}
.top-comment-ttl {
    line-height: 2;
    text-align: center;
    & span {
        position: relative;
        padding-left: 2em;
    }
    & span::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 0.5em;
        width: 0.7em;
        height: 1px;
        background-color: #000;
    }
    @include _mq-down(sm) {
        font-size: 1.5rem;
        & span.border_pc-only {
            padding-left: 0;
            display: inline-block;
        }
        & span.border_pc-only::before {
            display: none;
        }
    }
}
*/
/* ------------------------------
   loopSlider
------------------------------
.loopSliderWrap {
    top: 0;
    left: 0;
    height: 200px;
    overflow: hidden;
    position: absolute;
}

.loopSlider {
    margin: 0 auto;
    width: 100%;
    height: 200px;
    text-align: left;
    position: relative;
    overflow: hidden;
}

.loopSlider ul {
    height: 200px;
    float: left;
    overflow: hidden;
}

.loopSlider ul li {
    width: calc(310px + 25px);
    height: 200px;
    float: left;
    display: inline;
    overflow: hidden;
    padding-left: 25px;
}
*/
/* ------------------------------
   CLEARFIX ELEMENTS
------------------------------
.loopSliderWrap:after {
    content: "";
    display: none;
    clear: none;
}
*/
