// Object - page - news

.l-top-news {
    //width: calc(1170vw / 13.66);
    width: 100%;
    //margin-right: auto;
    //margin-left: auto;
    position: relative;
    //padding: calc(57vw / 13.66) calc(50vw / 13.66) 0 calc(50vw / 13.66);
    margin: _vw(55) auto _vw(55);
    width: _vw(1166);
    //margin-bottom: calc(55vw / 13.66);
    display: flex;
    @include _mq-down(sm) {
        width: 100%;
        margin: _vw(40, 375) auto _vw(50, 375);
        padding: 0 _vw(30, 375);
        display: block;
    }
}

.post-content-ttl {
    //font-size: calc(25vw / 13.66);
    font-size: _vw(25);
    width: _vw(235);
    line-height: 1;
    letter-spacing: 0.1em;
    @include _mq-down(sm) {
        font-size: _vw(20, 375);
        width: auto;
    }
}

.post-list {
    line-height: 1;
}
.post-list + .post-list {
    //margin-top: calc(25vw / 13.66);
    margin-top: _vw(25);
    @include _mq-down(sm) {
        margin-top: _vw(25, 375);
    }
}

.post-date {
    //font-size: calc(16vw / 13.66);
    font-size: _vw(16);
    //width: calc(100vw / 13.66);
    width: _vw(105);
    letter-spacing: 0.1em;
    font-weight: 400;
    @include _mq-down(sm) {
        width: auto;
        font-size: _vw(12, 375);
    }
}

.post-ttl {
    //font-size: calc(15vw / 13.66);
    font-size: _vw(15);
    letter-spacing: 0.05em;
    width: _vw(827);
    @include _mq-down(sm) {
        font-size: _vw(13, 375);
        line-height: 1.7692;
        padding-top: _vw(5, 375);
        width: 100%;
    }
}
.post-wrap {
    width: _vw(932);
}
@include _mq-down(sm) {
    .post-wrap {
        margin-top: _vw(35, 375);
        width: 100%;
    }
}

.post-more {
    position: absolute;
    left: 0;
    bottom: 0;
    //font-size: calc(15vw / 13.66);
    font-size: _vw(15);
    line-height: 1;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    & .icf-link-arrow::before {
        //font-size: calc(7vw / 13.66);
        font-size: _vw(7);
        //padding-right: calc(4vw / 13.66);
        padding-right: _vw(4);
        color: #f7931e;
    }
    & a {
        display: block;
    }
    @include _mq-down(sm) {
        position: static;
        margin-top: _vw(35, 375);
        font-size: _vw(15, 375);
        & .icf-link-arrow::before {
            font-size: _vw(7, 375);
            padding-right: _vw(4, 375);
        }
    }
}
